<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-data-table
      :expanded="expanded"
      :headers="displayHeaders"
      :height="tableSize"
      :items-per-page="pageSize"
      :items="items"
      :loading="loading"
      :search="search"
      :item-class="getRowClass"
      loading-text="Loading Items...Please Wait"
      loader-color="orange"
      dense
      fixed-header
      hide-default-footer
      item-key="id"
      show-select
      :disable-sort="true"
      v-model="selected">
      <template v-for="header in allHeaders" v-slot:[`header.${header.value}`]="">
        <div :key="header.value" @click="handleSort(header)">
          {{ header.text }}
          <v-icon v-if="header.showArrow && !header.descending" small color="white">mdi-chevron-up</v-icon>
          <v-icon v-if="header.showArrow && header.descending" small color="white">mdi-chevron-down</v-icon>
          <v-icon
            v-if="header.showArrow"
            color="red"
            small
            @click.stop="removeSort(header)"
            style="position: absolute; top: 0; right: 0; cursor: pointer;"
          >
            mdi-close
          </v-icon>
        </div>
      </template>
      <template #top>
        <v-toolbar flat color="toolbar">
          <v-row dense align="center" class="flex-right">
            <v-breadcrumbs large :items="breadcrumbs">
              <template #item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                  <span :class="!item.disabled ? 'primary--text' : ''">{{
                    item.text
                    }}</span>
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
            <v-spacer />
            <v-alert v-if="hasActiveChanges" type="warning" dense outlined class="mb-2">
              Warning: Please save changes before leaving Batch View.
            </v-alert>
            <v-menu v-if="isFiltered" bottom open-on-hover max-height="300">
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" class="orange white--text ml-2">
                  <v-icon color="white" class="mr-2" small>
                    mdi-filter-outline
                  </v-icon>
                  <span class="text-capitalize">
                    Results for {{ searchValues.length }} search values
                  </span>
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn v-on="on" @click="searchValues = []" class="white--text ml-2" icon x-small>
                        <v-icon small>mdi-close-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Clear Filter</span>
                  </v-tooltip>
                </v-chip>
              </template>
              <v-card>
                <v-card-title class="text-subtitle-2 pb-0">
                  Search Values:
                </v-card-title>
                <v-list>
                  <v-list-item v-for="(value, index) in searchValues" :key="index">
                    {{ value }}
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
            <v-btn v-if="tprProTenants" class="grey lighten-2" @click="openModal">
              <v-icon>mdi-table-edit</v-icon>
            </v-btn>

            <v-dialog v-model="showModal" max-width="600px">
              <v-card>
                <v-card-title class="main white--text pa-2 centered-title">
                  <span>Ordering Headers</span>
                </v-card-title>
                <v-card-text>
                  <v-form>
                    <v-container>
                      <div class="row">
                        <!-- Draggable List 1 (Visible Headers) -->
                        <div class="col-6">
                          <h3 class="header-column-name">Display Order</h3>
                          <draggable class="list-group" :list="displayHeaders" group="headers" @end="onDragEnd">
                            <div v-for="header in displayHeaders" :key="header.value">
                              <div v-if="header.value !== 'data-table-select'" class="list-group-item">
                                {{ header.text }}
                              </div>
                            </div>
                          </draggable>
                        </div>

                        <!-- Draggable List 2 (Hidden Headers) -->
                        <div class="col-6">
                          <h3 class="header-column-name">Hide</h3>
                          <draggable class="list-group" :list="hideHeaders" group="headers" @end="onDragEnd">
                            <div
                              class="list-group-item"
                              v-for="header in hideHeaders"
                              :key="header.value">
                              {{ header.text }}
                            </div>
                          </draggable>
                        </div>
                      </div>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="main white--text" @click="resetHeaders">Reset</v-btn>
                  <v-btn color="green darken-1 white--text" @click="saveHeadersOrder">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-menu
              :close-on-content-click="false"
              :nudge-bottom="3"
              :value="srpMenu"
              bottom>
              <template #activator="{ on: menu, attrs }">
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn :disabled="!selected.length" small :readonly="!selected.length" @click="srpMenu = true"
                      class="grey lighten-2" height="38" v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                      <v-icon>mdi-pencil-box-multiple-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </template>
              <v-card class="fill" width="200">
                <v-card-title class="pb-0 text-subtitle-1"> Edit </v-card-title>
                <v-form class="px-4 pt-4" ref="form">
                  <v-text-field :rules="multiplierRules" background-color="input" dense label="Multiplier" outlined
                    type="number" v-model="multiplier">
                  </v-text-field>
                  <v-text-field :rules="srpRules" background-color="input" dense label="SRP" outlined placeholder="0.00"
                    prefix="$" type="number" v-model="srp">
                  </v-text-field>
                </v-form>
                <v-card-actions class="fill">
                  <v-spacer />
                  <v-btn :class="body" @click="; (srp = ''), (srpMenu = false)" text>
                    Cancel
                  </v-btn>
                  <v-btn :class="body" @click="bulkUpdate" class="blue white--text mr-2">
                    Update
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
            <v-menu :close-on-content-click="false" :nudge-bottom="3" :value="deleteMenu" bottom>
              <template #activator="{ on: menu, attrs }">
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn :disabled="!selected.length" small :readonly="!selected.length" @click="deleteMenu = true"
                      class="grey lighten-2" height="38" v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
              <v-card class="fill" width="200">
                <v-card-text align="center" class="black--text text-subtitle-1">
                  Delete {{ selected.length }} items?
                </v-card-text>
                <v-card-actions class="fill">
                  <v-spacer />
                  <v-btn :class="body" @click="deleteMenu = false" text>
                    Cancel
                  </v-btn>
                  <v-btn :class="body" @click="bulkDelete" class="red white--text mr-2">
                    Delete
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>

            <v-btn color="success" :loading="loading" :disabled="hasInvalidInputs" v-if="tenantsWithTPRSave.includes(`${$auth.tenant}`)" @click="prepareToSave" class="ml-2">
              <v-icon left>mdi-content-save</v-icon>
              Save
            </v-btn>
            <v-btn color="red" :loading="loading"
              v-if="tenantsWithTPRSave.includes(`${$auth.tenant}`) && hasActiveChanges" @click="refreshPage"
              class="ml-2">
              <v-icon left>mdi-refresh-circle</v-icon>
              Reset All
            </v-btn>

            <SearchFilters v-if="!isTprProRetailUser" v-model="searchParams" :categories="categoryList"
              :departments="departmentList" :promoCategories="promoCategoryList" :vendors="vendorList" @input="getItems"
              class="fixed-search-filters" />

            <SearchFilters v-else v-model="searchParams" :categories="categoryList" :departments="departmentList"
              :promoCategories="promoCategoryList" :vendors="vendorList" @input="getStoreItems" class="fixed-search-filters" />
            <div style="width: 10px;"></div>

            <v-tooltip top>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn v-show="showRemoveSortsButton" small class="red lighten-1" height="38" @click="removeAllSorts"
                  v-on="tooltip">
                  <v-icon color="white">mdi-sort-variant-remove</v-icon>
                </v-btn>
              </template>
              <span>Remove all custom ordering</span>
            </v-tooltip>

            <v-menu bottom :nudge-bottom="3" :close-on-content-click="false" :value="actionMenu">
              <template #activator="{ on: menu, attrs }">
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn :disabled="isSyncing" small class="grey lighten-2" height="38" v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }" @click="actionMenu = true">
                      <v-icon>mdi-cog-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Batch Actions</span>
                </v-tooltip>
              </template>
              <v-card class="fill">
                <v-list class="fill">
                  <v-list-item v-for="(action, index) in actions" :key="index" link
                    @click="action.method(selectedBatch)">
                    <v-list-item-icon>
                      <v-icon>{{ action.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ action.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </v-row>
        </v-toolbar>
        <v-divider />
      </template>
      <template #loading>
        <v-container class="ml-0"> Loading Items...Please Wait </v-container>
      </template>
      <template #[`header.data-table-select`]="{ on, props }">
        <v-simple-checkbox :ripple="false" v-on="on" v-bind="props" dark />
      </template>

      <template #[`item.excluded`]="{ item, on }">
        <v-simple-checkbox
          :value="item.excluded === '' || item.excluded === null"
          :ripple="false"
          v-on="on"
          @click="toggleExcluded(item)"
        />
      </template>

      <template #[`item.rules_applied`]="{ item }">
        <v-tooltip top :disabled="!hasRules(item.rules_applied)">
          <template #activator="{ on }">
            <v-icon v-if="hasRules(item.rules_applied)" v-on="on" color="success">
              mdi-check-circle
            </v-icon>
          </template>

          <v-card v-if="hasRules(item.rules_applied)" width="300">
            <v-card-title class="text-subtitle-2">Applied Rules</v-card-title>
            <v-divider />
            <v-list dense>
              <v-list-item v-for="(rule, index) in parseRules(item.rules_applied)" :key="index">
                <v-list-item-content class="pl-4">
                  <v-list-item-title>Rule: {{ rule.display_name }}</v-list-item-title>
                  <v-list-item-subtitle>Level: {{ getRuleDisplay(rule.level) }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="rule.priority != 52">Value: {{ rule.displayValue }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-tooltip>
      </template>

      <template #[`item.enforce_compliance`]="{ item }">
        <v-tooltip :disabled="!item.enforce_compliance" top>
          <template #activator="{ on }">
            <v-icon v-on="on" :color="item.enforce_compliance ? 'green' : 'grey lighten-1'">
              mdi-shield-check-outline
            </v-icon>
            <v-icon v-if="item.enforce_compliance"
              v-show="!SRPIsCompliant(item)" 
              v-on="on"
              :color="getWarningColor(item)">
              mdi-exclamation-thick
            </v-icon>
          </template>
          <span>Compliance Enforced</span>
          <br>
          <span>{{ 'Variance: $' + parseFloat(item.variance).toFixed(2) }}</span>
        </v-tooltip>
      </template>
      <template #[`item.upc`]="{ item }">
        <td class="noWrap">{{ item.upc > 2 ? item.upc : '' }}</td>
      </template>
      <template #[`item.cost`]="{ item }">
        <td class="noWrap">${{ formatCurrencyDisplay(item.cost) }}</td>
      </template>
      <template #[`item.amap_allowance`]="{ item }">
        <td class="noWrap">
          ${{ formatCurrencyDisplay(item.amap_allowance) }}
        </td>
      </template>
      <template #[`item.early_buy_allowance`]="{ item }">
        <td class="noWrap">
          ${{ formatCurrencyDisplay(item.early_buy_allowance) }}
        </td>
      </template>
      <template #[`item.tpr_billback_allowance`]="{ item }">
        <td class="noWrap">
          ${{ formatCurrencyDisplay(item.tpr_billback_allowance) }}
        </td>
      </template>
      <template #[`item.tpr_scan_allowance`]="{ item }">
        <td class="noWrap">
          ${{ formatCurrencyDisplay(item.tpr_scan_allowance) }}
        </td>
      </template>
      <template #[`item.edlc_billback_allowance`]="{ item }">
        <td class="noWrap">
          ${{ formatCurrencyDisplay(item.edlc_billback_allowance) }}
        </td>
      </template>
      <template #[`item.edlc_scan_allowance`]="{ item }">
        <td class="noWrap">
          ${{ formatCurrencyDisplay(item.edlc_scan_allowance) }}
        </td>
      </template>
      <template #[`item.unit_cost`]="{ item }">
        <td class="noWrap">${{ formatCurrencyDisplay(item.unit_cost) }}</td>
      </template>
      <template #[`item.net_unit_cost`]="{ item }">
        <td class="noWrap">${{ formatCurrencyDisplay(item.net_unit_cost) }}</td>
      </template>
      <template #[`item.gross_profit_percent`]="{ item }">
        <td class="noWrap">{{ item.gross_profit_percent | format }}%</td>
      </template>
      <template #[`item.retail_price`]="{ item }">
        <td class="noWrap">${{ formatCurrencyDisplay(item.retail_price) }}</td>
      </template>
      <template #[`item.obxi`]="{ item }">
        <td class="noWrap">{{ item.obxi }}</td>
      </template>
      <template #[`item.like_code`]="{ item }">
        <td class="noWrap">{{ item.like_code }}</td>
      </template>
      <template #[`item.start_dt`]="{ item }">
        <td class="noWrap">{{ formatDateDisplay(item.start_dt) }}</td>
      </template>
      <template #[`item.end_dt`]="{ item }">
        <td class="noWrap">{{ formatDateDisplay(item.end_dt) }}</td>
      </template>
      <template #[`item.rules_promotion_price`]="{ item }">
        <td class="noWrap" v-if="item.rules_promotion_price">
          ${{ formatCurrencyDisplay(item.rules_promotion_price) }}
        </td>
      </template>
      <template #[`item.original_promotion_price`]="{ item }">
        <td v-if="item.original_promotion_price && !isTprProRetailUser" class="noWrap">
          ${{ formatCurrencyDisplay(item.original_promotion_price) }}
        </td>
        <td v-else class="noWrap">
          ${{ formatCurrencyDisplay(item.promotion_price) }}
        </td>
      </template>
      <template #[`item.promotion_price`]="{ item }">
        <td>
          <v-row class="my-1 px-1 rounded">
            <v-col cols="4" class="pa-0">
              <v-text-field
                v-if="!tenantsWithTPRSave.includes(`${$auth.tenant}`)"
                dense
                outlined
                hide-details
                solo
                type="number"
                @change="multiplierUpdate(item, item.multiplier); blurActiveElement()"
                @input="updateGrossProfit(item)"
                :disabled="tprProTenants && item.excluded !== ''"
                v-model.number="item.multiplier">
              </v-text-field>
              <v-text-field v-else @input="handleInput(item, { target: { value: $event } }, 'multiplier')" dense outlined
                hide-details solo type="number" :disabled="tprProTenants && item.excluded !== ''"
                :value="item.multiplier">
              </v-text-field>
            </v-col>
            <v-icon size="20" class="mx-2">mdi-at</v-icon>
            <v-col class="pa-0">
              <v-text-field
                v-if="!tenantsWithTPRSave.includes(`${$auth.tenant}`)"
                :readonly="selected.length > 0"
                :rules="srpRules"
                prefix="$"
                background-color="input"
                class="px-0"
                dense
                outlined
                hide-details
                solo
                step=".01"
                type="number"
                v-model.number="item.promotion_price"
                @input="updateGrossProfit(item)"
                @change="srpUpdate(item, item.promotion_price); blurActiveElement()"
                :disabled="tprProTenants && item.excluded !== ''">
              </v-text-field>
              <v-text-field
                v-else
                :key="srprefresh"
                :readonly="selected.length > 0"
                :rules="srpRules"
                :value="item.promotion_price"
                @input="handleInput(item, {target: {value: $event}}, 'promotion_price')"
                prefix="$"
                background-color="input"
                class="px-0"
                dense
                outlined
                hide-details
                solo
                step=".01"
                type="number"
                :disabled="tprProTenants && item.excluded !== ''">
              </v-text-field>
            </v-col>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
              <v-btn
                v-if="tenantsWithTPRSave.includes(`${$auth.tenant}`)"
                @click="resetRow(item)"
                icon
                v-on="on"
                :style="{ visibility: changes.some(change => change.id === item.id) ? 'visible' : 'hidden' }">
                <v-icon>mdi-refresh-circle</v-icon>
              </v-btn>
              </template>
              <span>Reset Values</span>
            </v-tooltip>
          </v-row>
        </td>
      </template>
      <template #[`item.promo_info`]="{ item }">
        <v-menu :close-on-content-click="false" offset-x offset-y open-on-click right>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip top>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn @click="getPromosByRow(item)" color="primary" icon tabindex="-1" v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
              <span>View Promos</span>
            </v-tooltip>
          </template>
          <v-card width="400">
            <v-toolbar flat class="accent" dark>
              <v-toolbar-title>Included in Allowances</v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-container fluid class="ma-0 pa-0" style="max-height: 250px; scroll-y: auto">
              <v-list class="py-0" dense>
                <v-list-item v-for="promo in promosByRow" :key="promo.promo_id" link @click="openLink(promo.promo_id)">
                  <v-list-item-content class="primary--text">
                    <v-list-item-title>{{
                      promo.promo_name
                      }}</v-list-item-title>
                    <v-list-item-subtitle v-if="promosByRow.length > 0">
                      {{ promo.promo_number }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else>
                      No Promos to Show
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-container>
          </v-card>
        </v-menu>
      </template>
      <!-- As of right now, Store users dont get a Note field -->
      <template v-if="!isTprProRetailUser" #expanded-item="{ item, headers }">
        <td :colspan="headers.length" class="grey lighten-3 pa-0">
          <v-text-field tabindex="-1" background-color="grey lighten-3" color="primary" dense flat hide-details
            placeholder="Add Note" v-model="item.note" @change="noteUpdate(item)">
            <template #prepend-inner>
              <label class="font-weight-medium mx-3 pt-1">Note:</label>
            </template>
          </v-text-field>
        </td>
      </template>
      <template #footer>
        <v-divider></v-divider>
        <v-toolbar flat color="#fff" style="margin-top: 8px">
          <v-row align="center" style="padding-bottom: 3px">
            <v-spacer />
            <v-col cols="2" style="max-width: 150px">
              <v-select :items="pageSizes" :menu-props="{
                top: true,
                offsetY: true,
                maxHeight: 500
              }" class="mt-7" dense label="Items Per Page" v-model="pageSize">
              </v-select>
            </v-col>
            <v-btn :disabled="disablePrevious" @click="previous" class="mx-2" icon large>
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <small>Page {{ page }}</small>
            <v-btn :disabled="disableNext" @click="next" class="mx-2" icon large>
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </v-toolbar>
      </template>
    </v-data-table>
    <v-dialog v-model="showConfirmPopup" persistent width="400">
      <v-card class="modalCard" height="220">
        <v-toolbar flat dark class="main">
          <v-toolbar-title>Confirm SRP Values</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <!-- Single Column Wrapping All Rows -->
          <v-col>
            <!-- Checkbox Section -->
            <v-row class="align-center">
              <v-checkbox
                id="checkbox"
                v-model="confirmChecked"
                label="I understand that I am saving items with an SRP value that falls outside of the compliance range."
                hide-details
              ></v-checkbox>
            </v-row>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-row justify="center">
            <v-col cols="auto">
              <v-btn @click="cancelConfirm">Cancel</v-btn>
              <v-btn
                class="ml-3 green white--text"
                :disabled="!confirmChecked"
                @click="saveChanges"
              >
              Confirm Save
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AlertDialog :value.sync="alertDialog" :batch="selectedBatch" :total="total" @update="batchUpdate($event)" />
    <v-overlay absolute :value="exporting">
      <v-progress-circular indeterminate size="100">
        Exporting...
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import Items from '@/axios/items'
import { fullwidth } from '@/mixins/fullwidth'
import { paging } from '@/mixins/paging'
import { displayHelpers } from '@/mixins/displayHelpers'
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
import { shared } from '@/mixins/shared'
import Rules from '@/axios/rules-management.js'
import SearchFilters from '@/components/SearchFilters.vue'
import Draggable from 'vuedraggable'

export default {
  name: 'BatchView',
  components: { SearchFilters, Draggable },
  mixins: [fullwidth, displayHelpers, paging, userAccess, utils, shared],
  data() {
    return {
      actionMenu: false,
      selectedAdGroup: {},
      categoryList: [],
      confirmChecked: false,
      selectedStore: {},
      dateRange: [],
      deleteMenu: false,
      departmentList: [],
      promoCategoryList: [],
      vendorList: [],
      expanded: [],
      filtered: false,
      items: [],
      memoryItems: [],
      loading: true,
      multiplier: '',
      displayHeaders: [],
      hideHeaders: [],
      noteMenu: false,
      search: '',
      searchMenu: false,
      searchValues: [],
      searchParams: {},
      selectedRow: {},
      selected: [],
      sortBy: [],
      sort: null,
      showConfirmPopup: false,
      showRemoveSortsButton: false,
      showModal: false,
      selectedHeaders: [],
      srp: '',
      srprefresh: 0,
      srpMenu: false,
      tprDate: '',
      tprEndDate: '',
      changes: [],
      originalItems: []
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasActiveChanges) {
      if (confirm('You have unsaved changes. Do you want to discard changes?')) {
        this.changes = []
        localStorage.removeItem(`changes_${this.selectedBatch.id}`)
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  computed: {
    hasInvalidInputs() {
      return this.changes.some(change => {
        const item = this.items.find(i => i.id === change.id) || this.originalItems.find(i => i.id === change.id)
        const promotion_price = change.promotion_price !== undefined ? change.promotion_price : item.promotion_price
        const multiplier = change.multiplier !== undefined ? change.multiplier : item.multiplier

        return promotion_price <= 0 || multiplier <= 0 || (this.blockNonCompliantSaves && !this.SRPIsCompliant(item))
      })
    },
    hasActiveChanges() {
      return this.changes.length > 0
    },
    tprProTenants() {
      return this.$auth.tenant === 'alliance-retail-group'
    },
    blockNonCompliantSaves() {
      return this.tprProTenants && !this.isTprProRetailUser
    },
    userEmail() {
      return this.$auth.user.email
    },
    promosByRow() {
      return this.selectedRow ? this.selectedRow.promos : []
    },
    isFiltered() {
      return this.searchValues.length > 0 ? this.searchValues.length > 0 : false
    },
    breadcrumbs() {
      const tprDateFormatted = this.moment(this.tprDate).format(
        this.$dateConfig.date_display
      )
      return [
        {
          text: this.selectedAdGroup.name || this.selectedStore.store_name,
          exact: true,
          href: '/'
        },
        {
          text: this.selectedBatch?.batch_name
            ? `${this.selectedBatch.batch_name} (${tprDateFormatted})`
            : tprDateFormatted,
          exact: true,
          disabled: true
        }
      ]
    },
    tenantsWithTPRSave() {
      return ['alliance-retail-group']
    },
    allHeaders() {
      let headers = [
        { sortable: false, class: 'accent', value: 'data-table-select', text: 'Checkbox' },
        { text: 'Department', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'department' },
        { text: 'Included', align: 'start', sortable: false, filterable: true, class: 'accent white--text', value: 'excluded' },
        { text: 'Rules Applied', align: 'start', sortable: false, filterable: true, class: 'accent white--text', value: 'rules_applied' },
        { text: 'Rules Promotion Price', align: 'start', sortable: false, filterable: true, class: 'accent white--text', value: 'rules_promotion_price' },
        { text: 'Original Promotion Price', align: 'start', sortable: false, filterable: true, class: 'accent white--text', value: 'original_promotion_price' },
        { text: 'Category', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'category' },
        { text: 'Linked Group', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'linked_group' },
        { text: 'UPC', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'upc' },
        { text: 'Item ID', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'item_id' },
        { text: 'Description', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'description' },
        { text: 'Pack', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'qty_per_case' },
        { text: 'Size', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'pack_size' },
        { text: 'Case Cost', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'cost' },
        { text: 'Reg Unit Cost', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'unit_cost' },
        { text: 'AMAP', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'amap_allowance' },
        { text: 'EBA', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'early_buy_allowance' },
        { text: 'TPR Billback', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'tpr_billback_allowance' },
        { text: 'TPR Scan', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'tpr_scan_allowance' },
        { text: 'EDLC Billback', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'edlc_billback_allowance' },
        { text: 'EDLC Scan', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'edlc_scan_allowance' },
        { text: 'Net Unit Cost', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'net_unit_cost' },
        { text: 'Compliance Enforced', align: 'center', sortable: true, class: 'accent white--text', value: 'enforce_compliance' },
        { text: 'Multiplier @ SRP', align: 'center', sortable: true, filterable: true, class: 'accent white--text', value: 'promotion_price', width: '250', disabled: false },
        { text: 'GP%', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'gross_profit_percent' },
        { text: 'Retail Price', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'retail_price' },
        { text: 'Order Book Index', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'obxi' },
        { text: 'Like Code', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'like_code' },
        { text: 'Start Date', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'start_dt' },
        { text: 'End Date', align: 'start', sortable: true, filterable: true, class: 'accent white--text', value: 'end_dt' },
        { text: 'Promo Info', align: 'start', sortable: false, filterable: false, class: 'accent white--text', value: 'promo_info' }
      ]

      if (!this.tprProTenants) {
        headers = headers.filter(header => !['original_promotion_price',  'rules_promotion_price', 'rules_applied', 'excluded', 'obxi', 'like_code'].includes(header.value))
      }

      // ARG doesn't want these headers
      if (this.$auth.tenant === 'alliance-retail-group') {
        headers = headers.filter(header => !['like_code', 'linked_group'].includes(header.value))
      }

      // store users hide rules promo price and promo info.
      if (this.isTprProRetailUser) {
        headers = headers.filter(header => !['rules_promotion_price', 'promo_info'].includes(header.value))
      }

      return headers
    },
    disablePrevious() {
      return (this.page - 1) * this.pageSize === 0
    },
    disableNext() {
      return this.items.length < this.pageSize
    },
    srpRules() {
      const rules = []

      const valueRequired = v => !!v || 'SRP Required'
      rules.push(valueRequired)

      const positiveValue = v => parseFloat(v) >= 0 || 'Invalid SRP'
      rules.push(positiveValue)

      return rules
    },
    multiplierRules() {
      const rules = []

      const valueRequired = v => !!v || 'Multiplier Required'
      rules.push(valueRequired)

      const positiveValue = v => v > 0 || 'Invalid Multiplier'
      rules.push(positiveValue)

      return rules
    },
    isTprProRetailUser() {
      return this.userGroups.includes('tpr-manager.pro')
    },
  },
  watch: {
    srpMenu: {
      handler(newValue) {
        if (newValue === false) {
          this.$refs.form.reset()
        }
      }
    },
    searchMenu: {
      handler(newValue) {
        if (newValue === false) {
          this.$refs.form.reset()
        }
      }
    },
    searchValues: {
      handler(newValue, oldValue) {
        sessionStorage.setItem(
          'tpr_manager_item_search',
          JSON.stringify(newValue)
        )
        if (newValue.length !== oldValue.length) {
          this.page = 1
          this.loadItems()
        }
      }
    },
    searchParams: {
      handler (newValue) {
        if (newValue) {
          if (this.page > 1) {
            this.page = 1
          }
        }
      },
      deep: true
    },
    sortBy(newVal) {
      this.showRemoveSortsButton = newVal.length > 0 // Show button if sortBy length is greater than 1
    },
    changes: {
      handler(newValue) {
        if (newValue) {
          localStorage.setItem(`changes_${this.selectedBatch.id}`, JSON.stringify(newValue))
        }
      },
      deep: true,
    }
  },
  created() {
      this.nonTableHeight = 240
      this.loadUserHeaders()
      if(!this.isTprProRetailUser) {
        this.tableChanged = this.tableChange(this.getItems)
        const savedChanges = localStorage.getItem(`changes_${this.selectedBatch.id}`)
        if (savedChanges) {
          this.changes = JSON.parse(savedChanges)
        }
        this.cleanupOtherBatchChanges()

        const { ad_group, tpr_date, tpr_end_date, selected_batch } = this.$route.params
        const {
          tpr_manager_ad_group,
          tpr_manager_tpr_date,
          tpr_manager_tpr_end_date,
          tpr_manager_selected_batch,
          tpr_manager_item_search
        } = sessionStorage

        this.selectedAdGroup = ad_group || JSON.parse(tpr_manager_ad_group)
        this.tprDate = tpr_date || tpr_manager_tpr_date
        this.tprEndDate = tpr_end_date || tpr_manager_tpr_end_date
        this.selectedBatch =
          selected_batch || JSON.parse(tpr_manager_selected_batch)
        this.searchValues = tpr_manager_item_search
          ? JSON.parse(tpr_manager_item_search)
          : []

        this.getItems()
        this.tableChanged = this.tableChange(this.getItems)
      } else {
        this.tableChanged = this.tableChange(this.getStoreItems)
        const savedChanges = localStorage.getItem(`changes_${this.selectedBatch.id}`)
        if (savedChanges) {
          this.changes = JSON.parse(savedChanges)
        }
        this.cleanupOtherBatchChanges()
        const { store, tpr_date, tpr_end_date, selected_batch } = this.$route.params
        const {
          tpr_pro_store,
          tpr_manager_tpr_date,
          tpr_manager_tpr_end_date,
          tpr_manager_selected_batch,
          tpr_manager_item_search
        } = sessionStorage
        this.selectedStore = store || JSON.parse(tpr_pro_store)
        this.tprDate = tpr_date || tpr_manager_tpr_date
        this.tprEndDate = tpr_end_date || tpr_manager_tpr_end_date
        this.selectedBatch =
          selected_batch || JSON.parse(tpr_manager_selected_batch)
        this.searchValues = tpr_manager_item_search
          ? JSON.parse(tpr_manager_item_search)
          : []

        this.getStoreItems()
        this.tableChanged = this.tableChange(this.getStoreItems)
      }
  },
  mounted() {
    this.loadUserHeaders()
    this.loadUniqueValues()
    window.addEventListener('beforeunload', this.handleBeforeUnload)
  },
  methods: {
    loadItems() {
      if (this.isTprProRetailUser) {
        this.getStoreItems()
      } else {
        this.getItems()
      }
    },
    blurActiveElement() {
      document.activeElement.blur()
    },
    formatAllSRPValues(items) {
      items.forEach(item => {
        if (item.promotion_price !== null && item.promotion_price !== undefined) {
          item.promotion_price = parseFloat(item.promotion_price).toFixed(2)
        }
      })
    },
    updateGrossProfit(item) {
      const gp = this.calculateGrossProfit(item)
      Object.assign(item, { gross_profit_percent: gp })
    },
    calculateGrossProfit(item) {
      if (item.multiplier <= 0 || item.promotion_price <= 0) {
        // Return 0.00% for invalid inputs
        return 0.00;
      }

      const allowances = Number(item.amap_allowance) + Number(item.billback_allowance) + Number(item.tpr_billback_allowance) + Number(item.edlc_billback_allowance) + Number(item.vpap_tpr_billback_allowance) + Number(item.accrual_tpr_billback_allowance)
      const scanAllowances = Number(item.scan_allowance) + Number(item.edlc_scan_allowance) + Number(item.tpr_scan_allowance)

      const unitCost = ((Number(item.cost) - allowances) / Number(item.qty_per_case)) - scanAllowances
      const grossProfit = (((Number(item.promotion_price) / Number(item.multiplier)) - unitCost) / (Number(item.promotion_price) / Number(item.multiplier))) * 10000

      return Math.round(grossProfit) / 100
    },
    openModal() {
      this.showModal = true
    },
    syncHeadersOrder() {
      const userEmail = this.userEmail
      let allUserHeaders = JSON.parse(localStorage.getItem("userHeaders")) || {}

      allUserHeaders[userEmail] = this.selectedHeaders
      localStorage.setItem("userHeaders", JSON.stringify(allUserHeaders))

      const reorderedHeaders = this.allHeaders.filter(header =>
        this.selectedHeaders.includes(header.value)
      )

      this.displayHeaders = reorderedHeaders.sort((a, b) => {
        return this.selectedHeaders.indexOf(a.value) - this.selectedHeaders.indexOf(b.value)
      })

      this.hideHeaders = this.allHeaders.filter(header =>
        !this.selectedHeaders.includes(header.value)
      )
    },
    loadUserHeaders() {
      const userEmail = this.userEmail
      const allUserHeaders = JSON.parse(localStorage.getItem("userHeaders")) || {}

      if (allUserHeaders[userEmail]) {
        this.selectedHeaders = allUserHeaders[userEmail]
      } else {
        this.selectedHeaders = this.allHeaders.map(header => header.value)
      }

      this.syncHeadersOrder()
    },
    saveHeadersOrder() {
      const userEmail = this.userEmail
      let allUserHeaders = JSON.parse(localStorage.getItem("userHeaders")) || {}

      allUserHeaders[userEmail] = this.selectedHeaders
      localStorage.setItem("userHeaders", JSON.stringify(allUserHeaders))

      this.showModal = false
    },
    resetHeaders() {
      const userEmail = this.userEmail
      let allUserHeaders = JSON.parse(localStorage.getItem('userHeaders')) || {}

      delete allUserHeaders[userEmail]
      localStorage.setItem('userHeaders', JSON.stringify(allUserHeaders))

      this.selectedHeaders = this.allHeaders.map(header => header.value)
      this.syncHeadersOrder()
      this.showModal = false
    },
    onDragEnd() {
      // Ensure checkbox header remains in displayHeaders
      if (!this.displayHeaders.some(header => header.value === 'data-table-select')) {
        const checkboxHeader = this.allHeaders.find(header => header.value === 'data-table-select')
        if (checkboxHeader) {
          this.displayHeaders.unshift(checkboxHeader)
          this.hideHeaders = this.hideHeaders.filter(header => header.value !== 'data-table-select')
        }
      }
      const displayHeaderValues = this.displayHeaders.map(header => header.value)
      this.selectedHeaders = displayHeaderValues
    },
    async getPromosByRow(item) {
      const { id } = item
      const tprId = id
      try {
        const { data } = await Items.getPromosByRow(
          this.selectedBatch.id,
          tprId
        )
        this.selectedRow = data
      } catch (err) {
        console.log(err)
      }
    },
    openLink(promoId) {
      let baseUrl = process.env.VUE_APP_PROMO_PORTAL
      let prefix = this.$auth.tenantURLPrefix
      if (prefix) {
        baseUrl = `${prefix}.${baseUrl}`
      }
      const url = `https://${baseUrl}/promo?id=${promoId}`
      window.open(url)
    },
    async toggleExcluded(item) {
      try {
        if (item.excluded === '') {
          item.excluded = 'manual'
        }
        else if( item.excluded) {
          item.excluded = ''
        }
        else if (item.excluded === 'manual') {
          item.excluded = ''
        }
        await Rules.saveExclusion(item.id, this.$auth.user.email, item.excluded)
      } catch (error) {
        console.error('Error while toggling exclusion: ', error)
      }
    },
    getRowClass(item) {
      let classList = ''
      const promotion_price = item.promotion_price !== undefined ? item.promotion_price : item.srp
      if (item.gross_profit_percent === null || promotion_price <= 0 || item.multiplier <= 0) {
        return 'red white--text '
      }
      if (this.tprProTenants) {
        if (item.excluded !== '') {
          classList += 'disabled-row '
        }
        if (item.gross_profit_percent === null) {
          classList += 'red white--text '
        }
      } else {
        if (item.gross_profit_percent === null) {
          classList += 'red white--text '
        }
      }
      if (this.changes.some(change => change.id === item.id)) {
        if (!this.SRPIsCompliant(item) && this.blockNonCompliantSaves) {
          return 'red white--text '
        }
        if (this.tenantsWithTPRSave.includes(this.$auth.tenant))  {
          classList += 'yellow-row '
        }
      }
      return classList.trim()
    },
    getWarningColor(item) {
      if (this.changes.some(change => change.id === item.id) && this.blockNonCompliantSaves)
        return 'white'
      return 'red'
    },
    parseRules(rulesApplied) {
      try {
        let parsedRules = typeof rulesApplied === 'string' ? JSON.parse(rulesApplied) : rulesApplied


        return parsedRules.map(rule => {
          if (rule.name && rule.name.toLowerCase().includes('discount')) {
            rule.displayValue = `$${rule.value}`
          } else if (rule.name && rule.name.toLowerCase().includes('pct')) {
            rule.displayValue = `${rule.value}%`
          } else {
            rule.displayValue = rule.value
          }
          return rule
        })
      } catch (err) {
        console.error('Failed to parse rules_applied:', err)
        return []
      }
    },
    computedPromotionPrice(item) {
      let price
      if (!this.isTprProRetailUser && this.tprProTenants) {
        if (item.version_id > 1) {
          price = item.promotion_price
        } else if (item.version_id === 1 && item.rules_applied) {
          price = item.rules_promotion_price
        } else {
          price = item.promotion_price
        }
      } else {
        price = item.promotion_price
      }

      return this.formatCurrencyDisplay(price)
    },
    hasRules(rulesApplied) {
      if (!rulesApplied || rulesApplied === '[]') {
        return false
      }

      try {
        const parsed = JSON.parse(rulesApplied)
        return Array.isArray(parsed) && parsed.length > 0
      } catch (err) {
        console.error('Invalid JSON in rules_applied:', err)
        return false
      }
    },
    getRuleDisplay(ruleKey) {
      const ruleMapping = {
        'ad_group': 'Ad Group',
        'store': 'Store',
        'department': 'Department',
        'category': 'Category',
        'vendor': 'Vendor'
      }
      return ruleMapping[ruleKey] || ruleKey
    },
    async getStoreItems() {
      this.loading = true

      const sortString = this.sortBy
        .map(([column, order]) => `${column} ${order}`)
        .join(', ')

      const params = {
        ...this.searchParams,
        sort_by: sortString,
      }

      try {
        const { data } = await Rules.getStoreItems(
          this.selectedStore.store_party_id,
          this.selectedBatch.tpr_batch_id,
          params,
          this.pageSize,
          this.from
        )
        this.formatAllSRPValues(data)
        this.items = data
        const updatedItems = data.map(item => {
          const changedItem = this.changes.find(change => change.id === item.id)
          if (changedItem) {
            const updatedItem = {
              ...item,
              ...changedItem,
              gross_profit_percent: this.calculateGrossProfit({ ...item, ...changedItem }),
        }
            updatedItem.srp = this.computedPromotionPrice(updatedItem)
            return updatedItem
          }
          return item
        })
        this.items = updatedItems.map(item => ({
          ...item,
          gross_profit_percent: this.calculateGrossProfit(item),
          srp: this.changes.find(change => change.id === item.id)?.srp || this.computedPromotionPrice(item),
        }))
        this.expanded = this.items
      } catch (err) {
        console.log(err)
        const message = err?.response?.data?.message || err.message
        this.alert(`No Matching Items Found: ${message}`)
      } finally {
        this.loading = false
      }
    },
    async handleSort(header) {
      const existingSortIndex = this.sortBy.findIndex(([column]) => column === header.value)

      if (existingSortIndex === -1) {
        this.sortBy.push([header.value, 'asc'])
        this.contextColumn = header.value
        header.descending = false
        header.showArrow = true
      } else {
        const currentOrder = this.sortBy[existingSortIndex][1]
        if (currentOrder === 'asc') {
          this.sortBy[existingSortIndex][1] = 'desc'
          header.descending = true
          this.contextColumn = header.value
        } else {
          this.sortBy[existingSortIndex][1] = 'asc'
          header.descending = false
          this.contextColumn = header.value
        }
      }

      this.loadItems()
    },
    async removeSort(header) {
      const indexToRemove = this.sortBy.findIndex(([column]) => column === header.value)
      if (indexToRemove !== -1) {
        this.sortBy.splice(indexToRemove, 1)
      }
      header.descending = null
      header.showArrow = false
      this.loadItems()
    },
    async removeAllSorts() {
      // Clear all sorting
      this.sortBy = []

      // Reset all headers to remove sort arrows and state
      this.allHeaders.forEach((header) => {
        header.descending = null
        header.showArrow = false
      })

      // Re-fetch the items without sorting
      this.loadItems()
    },
    async getItems() {
      this.loading = true

      const sortString = this.sortBy
        .map(([column, order]) => `${column} ${order}`)
        .join(', ')

      const params = {
        ...this.searchParams,
        sort_by: sortString
      }

      console.log(this.searchParams)

      try {
        const { data } = await Items.getItems(
          this.selectedBatch.id,
          params,
          this.pageSize,
          this.from
        )
        this.formatAllSRPValues(data)
        const updatedItems = data.map(item => {
        const changedItem = this.changes.find(change => change.id === item.id)
        if (changedItem) {
        const updatedItem = {
          ...item,
          ...changedItem,
          gross_profit_percent: this.calculateGrossProfit({ ...item, ...changedItem }),
          srp: changedItem?.srp || this.computedPromotionPrice({ ...item, ...changedItem })
        }
        updatedItem.srp = this.computedPromotionPrice(updatedItem)
        return updatedItem
        }
        return {
            ...item,
            gross_profit_percent: this.calculateGrossProfit(item),
            srp: this.computedPromotionPrice(item),
          }
        })
        this.items = updatedItems
        this.expanded = this.items

      } catch (err) {
        console.error(err)
        const message = err?.response?.data?.message || err.message
        this.alert(`No Matching Items Found: ${message}`)
      } finally {
        this.loading = false
      }
    },
    async loadUniqueValues() {
      let batchId = this.selectedBatch.id

      //If batch id is set
      if (batchId) {
        try {
          if (this.isTprProRetailUser) {
            const categoryResponse = await Rules.getUniqueValues(batchId, 'category')
            this.categoryList = Array.isArray(categoryResponse.data) ? categoryResponse.data : []

            const departmentResponse = await Rules.getUniqueValues(batchId, 'department')
            this.departmentList = Array.isArray(departmentResponse.data) ? departmentResponse.data : []

            const promoCategoryResponse = await Rules.getUniqueValues(batchId, 'promo_category_name')
            this.promoCategoryList = Array.isArray(promoCategoryResponse.data) ? promoCategoryResponse.data : []

            const vendorResponse = await Rules.getUniqueValues(batchId, 'vendor')
            this.vendorList = Array.isArray(vendorResponse.data) ? vendorResponse.data : []
          } else {
            const categoryResponse = await Items.getUniqueValues(batchId, 'category')
            this.categoryList = Array.isArray(categoryResponse.data) ? categoryResponse.data : []

            const departmentResponse = await Items.getUniqueValues(batchId, 'department')
            this.departmentList = Array.isArray(departmentResponse.data) ? departmentResponse.data : []

            const promoCategoryResponse = await Items.getUniqueValues(batchId, 'promo_category_name')
            this.promoCategoryList = Array.isArray(promoCategoryResponse.data) ? promoCategoryResponse.data : []

            const vendorResponse = await Items.getUniqueValues(batchId, 'vendor')
            this.vendorList = Array.isArray(vendorResponse.data) ? vendorResponse.data : []
          }
        } catch (error) {
          console.error('Error loading unique values:', error)
          this.alert('Failed to load categories or departments.')
        }
      }
    },
    async resetRow(item) {
      this.loading = true
      try {
        const originalItem = this.originalItems.find(origItem => origItem.id === item.id)
        if (originalItem) {
          this.$set(item, 'multiplier', originalItem.multiplier)
          this.$set(item, 'promotion_price', originalItem.promotion_price)
          this.updateGrossProfit(item)
          this.srprefresh++
          const itemIndex = this.items.findIndex(i => i.id === item.id)
          if (itemIndex !== -1) {
            this.$set(this.items, itemIndex, { ...this.items[itemIndex], multiplier: originalItem.multiplier, promotion_price: originalItem.promotion_price })
          }
        }
        this.changes = this.changes.filter(change => change.id !== item.id)
        this.notify('Row reset successfully')
      } catch (err) {
        console.error(err)
        const message = err?.response?.data?.message || err.message
        this.alert(`Error resetting values: ${message}`)
      } finally {
        this.loading = false
      }
    },
    resetChanges() {
      this.changes = []
      localStorage.removeItem(`changes_${this.selectedBatch.id}`)
      this.items = JSON.parse(JSON.stringify(this.originalItems))
    },
    cleanupOtherBatchChanges() {
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i)
        if (key.startsWith('changes_') && key !== `changes_${this.selectedBatch.id}`) {
          localStorage.removeItem(key)
        }
      }
    },
    handleBeforeUnload(event) {
      if (this.hasActiveChanges) {
        event.preventDefault()
        event.returnValue = ''
      }
    },
    refreshPage() {
      console.log('here')
      this.changes = []
      localStorage.removeItem(`changes_${this.selectedBatch.id}`)
      location.reload()
    },
    async noteUpdate(item) {
      const payload = [
        {
          id: item.id,
          promotion_price: item.promotion_price,
          multiplier: item.multiplier,
          note: item.note
        }
      ]
      try {
        await Items.tprUpdate(payload)
        this.notify('Note updated successfully')
      } catch (err) {
        console.error(err)
        const message = err?.response?.data?.message || err.message
        this.alert(`Error updating note: ${message}`)
      } finally {
        await this.loadItems()
        this.loading = false
      }
    },
    validateInputs(item) {
      if (item.promotion_price <= 0 || item.multiplier <= 0) {
        this.alert("SRP and Multiplier must be greater than zero.")
        return false
      }
      return true
    },
    SRPIsCompliant(item) {
      let complianceLimit = 0
      if (this.isTprProRetailUser) {
        complianceLimit = (parseFloat(item.original_promotion_price) + parseFloat(item.variance)).toFixed(2) * item.multiplier
      } else {
        complianceLimit = parseFloat(item.rules_promotion_price + item.variance).toFixed(2) * item.multiplier
      }
      let formattedPrice = parseFloat(item.promotion_price)

      if (formattedPrice > complianceLimit) {
        return false
      }
      return true
    },
    async srpUpdate(item, srp) {
      if (!this.validateInputs(item)) return
      this.loading = true
      let payload = []
      if (this.selected.length) {
        this.selected.forEach(item => (item.promotion_price = this.srp))
      } else {
        payload = [
          {
            id: item.id,
            promotion_price: srp,
            multiplier: item.multiplier,
            note: item.note
          }
        ]
      }
      // Logic in place to seperate between a Store User and an Ad group User -- specific flag: this.isTprProRetailUser
      if(!this.isTprProRetailUser){
        try {
          await Items.tprUpdate(payload)
          this.selected = []
          this.srp = ''
          this.srpMenu = false
          this.notify('SRP updated successfully')
        } catch (err) {
          console.error(err)
          const message = err?.response?.data?.message || err.message
          this.alert(`Error updating SRP: ${message}`)
        } finally {
          await this.loadItems()
          this.loading = false
        }
      } else {
        this.storeSrpMultiplierUpdate(payload) //item, null, srp
      }
    },
    async storeSrpMultiplierUpdate(payload) {
      try {
        await Rules.storeSrpMultiplier(payload)
        this.notify('Item updated successfully')
        this.selected = []
        this.srp = ''
        this.srpMenu = false
      } catch (err) {
        console.error('store side srp & multiplier error: ', err)
        const message = err?.response?.data?.message || err.message
        this.alert(`Error updating store items: ${message}`)
      } finally {
        await this.getStoreItems()
      }
    },
    async multiplierUpdate(item, multiplier) {
      if (!this.validateInputs(item)) return
      const payload = [
        {
          id: item.id,
          promotion_price: item.promotion_price,
          multiplier: multiplier,
          note: item.note
        }
      ]
      try {
        await Items.tprUpdate(payload)
        this.notify('Multiplier updated successfully')
      } catch (err) {
        console.error(err)
        const message = err?.response?.data?.message || err.message
        this.alert(`Error updating multiplier: ${message}`)
      } finally {
        await this.loadItems()
        this.loading = false
      }
    },
    async handleInput(item, event, field) {
      if (!event || !event.target) {
        console.error('Invalid event object:', event)
        return
      }
      const value = event.target.value
      const existingChange = this.changes.find(change => change.id === item.id)
      if (existingChange) {
        existingChange[field] = value
      } else {
        const originalItem = this.originalItems.find(origItem => origItem.id === item.id)
        if (!originalItem) {
          this.originalItems.push({ ...item })
        }
        this.changes.push({ id: item.id, [field]: value, item_id: item.item_id })
      }
      item[field] = value
      this.updateGrossProfit(item)
    },
    async prepareToSave() {
      let priceOutsideOfCompliance = false

      // Warn NON-ARG Manager users if saving SRP values outside of compliance
      if (this.isTprProRetailUser) {
        for (const change of this.changes) {
          const item = this.items.find(i => i.id === change.id) || this.originalItems.find(i => i.id === change.id)
          if (item) {
            if (item.enforce_compliance && !this.SRPIsCompliant(item)) {
              priceOutsideOfCompliance = true
              break
            }
          }
        }
        
        if (priceOutsideOfCompliance) {
          this.showConfirmPopup = true
          return
        } 
      }

      await this.saveChanges()
    },
    cancelConfirm() {
      this.loading = false
      this.confirmChecked = false
      this.showConfirmPopup = false
    },
    async saveChanges() {
      this.showConfirmPopup = false
      this.confirmChecked = false
      this.loading = true
      const payloads = []

      for (const change of this.changes) {
        const item = this.items.find(i => i.id === change.id) || this.originalItems.find(i => i.id === change.id)
        if (item) {
          const payload = {
            id: item.id,
            promotion_price: change.promotion_price !== undefined ? change.promotion_price : item.promotion_price,
            multiplier: change.multiplier !== undefined ? change.multiplier : item.multiplier,
            note: item.note
          }
          payloads.push(payload)
        }
      }

      if (payloads.length > 0 && !this.isTprProRetailUser) {
        try {
          await Items.tprUpdate(payloads)
          this.notify('Changes saved successfully')
        } catch (err) {
          console.error(err)
          const message = err?.response?.data?.message || err.message
          this.alert(`Error updating items: ${message}`)
        } finally {
          await this.loadItems()
        }
      } else if (this.isTprProRetailUser) {
        await this.storeSrpMultiplierUpdate(payloads)
      }

      this.changes = []
      this.originalItems = []
      this.selected = []
      this.srp = ''
      this.srpMenu = false
      this.loading = false
    },
    async bulkUpdate() {
      if (this.srp <= 0 || this.multiplier <= 0) {
        this.alert("SRP and Multiplier must be greater than zero.")
        return
      }

      // Only do apply if ARG but not TPR Pro Retailer (they have their own function)
      if (this.tprProTenants && !this.isTprProRetailUser) {
        console.log("Bulk applying")
        this.bulkApply()
      } else {
        this.bulkSave()
      }
    },
    async bulkSave() {
      this.loading = true
        const payload = this.selected.map(item => {
          return {
            id: item.id,
            promotion_price: this.srp ? this.srp : item.promotion_price,
            multiplier: this.multiplier ? this.multiplier : item.multiplier,
            note: item.note
          }
        })
        if(!this.isTprProRetailUser){
        try {
          await Items.tprUpdate(payload)
          this.selected = []
          this.srp = ''
          this.srpMenu = false
          this.notify('TPR updated successfully')
        } catch (err) {
          console.error(err)
          const message = err?.response?.data?.message || err.message
          this.alert(`Error updating TPR: ${message}`)
        } finally {
          await this.loadItems()
          this.loading = false
        }
      } else {
        this.storeSrpMultiplierUpdate(payload)
      }
    },
    async bulkApply() {
      // Applies data to the SRP input boxes, but doesn't save it in the backend. Must use save button to save in DB
      for (const item of this.selected) {
        const newSRP = this.srp ? this.srp : item.promotion_price
        const newMult = this.multiplier ? this.multiplier : item.multiplier
        const existingChange = this.changes.find(change => change.id === item.id)
        if (existingChange) {
          existingChange['promotion_price'] = newSRP
          existingChange['multiplier'] = newMult
        } else {
          const originalItem = this.originalItems.find(origItem => origItem.id === item.id)
          if (!originalItem) {
            this.originalItems.push({ ...item })
          }
          this.changes.push({ id: item.id, promotion_price: newSRP, multiplier: newMult, item_id: item.item_id })
        }
        item.promotion_price = newSRP
        item.multiplier = newMult
      }
      this.selected = []
      this.srp = ''
      this.multiplier = ''
      this.srpMenu = false
      this.notify('TPR Updated - Save changes to confirm')
      this.loadItems()

    },
    async bulkDelete() {
      this.loading = true
      try {
        await Promise.allSettled(
          this.selected.map(item => Items.tprDelete(item.id))
        )
        this.deleteMenu = false
        this.selected = []
        this.notify('TPRs deleted successfully')
      } catch (err) {
        console.error(err)
        const message = err?.response?.data?.message || err.message
        this.alert(`Error deleting TPRs: ${message}`)
      } finally {
        this.loadItems()
      }
    },
    beforeDestroy() {
      window.removeEventListener('beforeunload', this.handleBeforeUnload)
    },
  }
}
</script>
<style>
.disabled-row {
  opacity: 0.5;
}

.yellow-row {
  background-color: yellow;
}

.v-data-table tbody tr.yellow-row:hover {
  background-color: yellow !important;
}

.headerModal {
  padding: 10px;
  margin: 0%
}

.flex-right {
  display: inline-flex;
  justify-content: flex-end !important;
  gap: 10px;
  align-items: center;
  flex-wrap: nowrap;
}

.flex-right>* {
  flex-grow: 0;
  flex-shrink: 1;
  white-space: nowrap;
}
.fixed-search-filters {
  width: 550px;
  flex-grow: 0;
  flex-shrink: 1;
  min-width: 100px;
  overflow: visible;
}
.header-column-name {
  text-align: center; 
  margin-bottom: 10px; 
  font-weight: bold; 
  max-width: 80%;
}

.list-group {
  margin-top: 10px;
  min-height: 100%
}

.list-group-item {
  padding: 8px; 
  border: 1px solid #e0e0e0;
  margin-bottom: 2px; 
  border-radius: 4px;
  max-width: 80%;
}
.centered-title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
