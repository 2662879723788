import axios from "axios"
import AuthToken from "@/auth/auth-token"

class Rules {
  constructor() {
    const base = axios.create({
      baseURL: process.env.VUE_APP_RULES_MANAGEMENT_API,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
      }
    })
    this.rules = base
  }

  async getRules(){ 
    await AuthToken.setAuth(this.rules)
    return this.rules({
      url: '/rules',
      method: 'GET'
    })
  }

  async getRulesets(){ 
    await AuthToken.setAuth(this.rules)
    return this.rules({
      url: '/rulesets',
      method: 'GET'
    })
  }

  async updateRulesets(ruleBody){
    await AuthToken.setAuth(this.rules)
    return this.rules({
      url: '/rulesets',
      method: 'PUT',
      data: ruleBody
    })
  }

  async createRulesets(ruleBody) {
    await AuthToken.setAuth(this.rules)
    return this.rules({
      url: '/rulesets',
      method: 'POST',
      data: ruleBody
    })
  }

  async deleteRulesets(ruleset_id) {
    await AuthToken.setAuth(this.rules)
    return this.rules({
      url: '/rulesets',
      method: 'DELETE',
      params: {
        ruleset_id: ruleset_id
      }
    })
  }

  async getPartyRelationships(partyID) {
    await AuthToken.setAuth(this.rules)
    return this.rules({
      url: '/relationship',
      method: 'GET',
      params: {
        party_id: partyID,
        tenant: 'alliance-retail-group'
      }
    })
  }

  async getDepartments() {
    await AuthToken.setAuth(this.rules)
    return this.rules({
      url: '/departments',
      method: 'GET',
    })
  }

  async getCategories() {
    await AuthToken.setAuth(this.rules)
    return this.rules({
      url: '/categories',
      method: 'GET',
    })
  }

  async saveExclusion(id, updated_by, excluded) {
    await AuthToken.setAuth(this.rules)
    return this.rules({
      url: '/exclusion',
      method: 'PUT',
      params: {
        tpr_id: id, 
        updated_by: updated_by,
        excluded: excluded
      }
    })
  }

  async getStores( user_email, tenant ) {
    await AuthToken.setAuth(this.rules)
    return this.rules({
      url: '/stores-by-email',
      method: 'GET',
      params: {
        email: user_email,
        tenant: tenant
      }
    })
  }

  async getStoreBatches(
    storeId,
    startDt,
    endDt,
    limit = 100,
    offset = 0
  ){
    await AuthToken.setAuth(this.rules)
    return this.rules({
      url: '/store-batch',
      method: 'GET',
      params: {
        store_id: storeId,
        start_dt: startDt,
        end_dt: endDt,
        limit: limit,
        offset: offset
      }
    })
  }

  async publishTprReport(batch_id){
    await AuthToken.setAuth(this.rules)
    return this.rules({
      url: '/publish-tpr-report',
      method: 'POST',
      params: {
        batch_id: batch_id
      }
    })
  }

  async syncStoreBatch(batch_id, store_id){
    await AuthToken.setAuth(this.rules)
    return this.rules({
      url: '/sync-store-tpr-report',
      method: 'POST',
      params : {
        batch_id: batch_id,
        store_id: store_id
      }
    })
  }

  async getStoreItems(storeId, batchId, storeItemSearchVM, limit = 100, offset = 0) {
    await AuthToken.setAuth(this.rules)
    return this.rules({
      url: '/search-store-items',
      method: 'POST',
      data: storeItemSearchVM,
      params: {
        batch_id: batchId,
        store_id: storeId,
        limit,
        offset
      }
    })
  }

  async getUniqueValues(batch_id, column) {
    await AuthToken.setAuth(this.rules)
    return this.rules({
      url: `/unique_batch_values/${batch_id}`,
      method: 'POST',
      params: {
        column: column
      }
    })
  }

  // call for the store side srp and multiplier. Its different from the ad group level
  async storeSrpMultiplier(requestBody){
    await AuthToken.setAuth(this.rules)
    return this.rules({
      url: '/store-item-update',
      method: 'PUT',
      data: requestBody
    })
  }
}

export default new Rules()