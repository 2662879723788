var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { "no-gutters": "" } },
    [
      _c(
        "v-col",
        { staticClass: "pa-0", attrs: { cols: "11" } },
        [
          _c(
            "v-select",
            {
              staticClass: "scrollable-select",
              attrs: {
                label: "Search Filters",
                items: _vm.availableFilters,
                "item-text": "name",
                "item-value": "key",
                "background-color": "input",
                "menu-props": _vm.menuProps,
                disabled: _vm.disabled,
                multiple: "",
                outlined: "",
                "hide-details": "",
                clearable: "",
                "small-chips": "",
                dense: "",
              },
              on: { "click:clear": _vm.removeAllFilters },
              scopedSlots: _vm._u([
                {
                  key: "selection",
                  fn: function ({ attrs, item }) {
                    return [
                      _c(
                        "v-chip",
                        _vm._b(
                          {
                            attrs: {
                              color: item.color || null,
                              dark: Boolean(item.color),
                              small: "",
                            },
                          },
                          "v-chip",
                          attrs,
                          false
                        ),
                        [
                          _vm._v(" " + _vm._s(_vm.getFilterText(item)) + " "),
                          _c(
                            "v-icon",
                            {
                              attrs: { small: "", right: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeFilter(item.key, true)
                                },
                              },
                            },
                            [_vm._v(" mdi-close ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "item",
                  fn: function ({ item, on, attrs }) {
                    return [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-action",
                            [
                              item.key === "is_shipper"
                                ? _c("v-simple-checkbox", {
                                    attrs: {
                                      label: "Shipper",
                                      color: "primary",
                                      ripple: false,
                                    },
                                    model: {
                                      value: _vm.params.is_shipper,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.params, "is_shipper", $$v)
                                      },
                                      expression: "params.is_shipper",
                                    },
                                  })
                                : item.key === "private_label_indicator"
                                ? _c("v-simple-checkbox", {
                                    attrs: {
                                      label: "Shipper",
                                      color: "primary",
                                      ripple: false,
                                    },
                                    model: {
                                      value: _vm.params.private_label_indicator,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.params,
                                          "private_label_indicator",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "params.private_label_indicator",
                                    },
                                  })
                                : _c("v-simple-checkbox", {
                                    attrs: {
                                      value: _vm.isFilterActive(item.key),
                                      disabled: _vm.isCheckboxDisabled(item),
                                      color: "primary",
                                      ripple: false,
                                    },
                                  }),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            { staticStyle: { overflow: "visible" } },
                            [
                              item.key === "category"
                                ? _c("v-autocomplete", {
                                    attrs: {
                                      label: item.name,
                                      items: _vm.sortedCategories,
                                      "item-text": "name",
                                      "item-value": "id",
                                      multiple: "",
                                      "small-chips": "",
                                      "deletable-chips": "",
                                      "hide-details": "",
                                      clearable: "",
                                      outlined: "",
                                      dense: "",
                                    },
                                    on: {
                                      change: _vm.handleCategoryChange,
                                      "click:clear": function ($event) {
                                        return _vm.removeFilter(item.key, true)
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function ({ item, on, attrs }) {
                                            return [
                                              _c(
                                                "v-list-item",
                                                _vm._g(
                                                  _vm._b(
                                                    {},
                                                    "v-list-item",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-list-item-action",
                                                    [
                                                      _c("v-checkbox", {
                                                        attrs: {
                                                          value:
                                                            attrs.inputValue,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.params.category,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.params, "category", $$v)
                                      },
                                      expression: "params.category",
                                    },
                                  })
                                : item.key === "department"
                                ? _c("v-autocomplete", {
                                    attrs: {
                                      label: item.name,
                                      items: _vm.sortedDepartments,
                                      "item-text": "name",
                                      "item-value": "id",
                                      multiple: "",
                                      "small-chips": "",
                                      "deletable-chips": "",
                                      "hide-details": "",
                                      clearable: "",
                                      outlined: "",
                                      dense: "",
                                    },
                                    on: {
                                      change: _vm.handleDepartmentChange,
                                      "click:clear": function ($event) {
                                        return _vm.removeFilter(item.key, true)
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function ({ item, on, attrs }) {
                                            return [
                                              _c(
                                                "v-list-item",
                                                _vm._g(
                                                  _vm._b(
                                                    {},
                                                    "v-list-item",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-list-item-action",
                                                    [
                                                      _c("v-checkbox", {
                                                        attrs: {
                                                          value:
                                                            attrs.inputValue,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.params.department,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.params, "department", $$v)
                                      },
                                      expression: "params.department",
                                    },
                                  })
                                : item.key === "promo_category_name"
                                ? _c("v-autocomplete", {
                                    attrs: {
                                      label: item.name,
                                      items: _vm.sortedPromoCat,
                                      "item-text": "name",
                                      "item-value": "id",
                                      multiple: "",
                                      "small-chips": "",
                                      "deletable-chips": "",
                                      "hide-details": "",
                                      clearable: "",
                                      outlined: "",
                                      dense: "",
                                    },
                                    on: {
                                      change: _vm.handlePromoCatChange,
                                      "click:clear": function ($event) {
                                        return _vm.removeFilter(item.key, true)
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function ({ item, on, attrs }) {
                                            return [
                                              _c(
                                                "v-list-item",
                                                _vm._g(
                                                  _vm._b(
                                                    {},
                                                    "v-list-item",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-list-item-action",
                                                    [
                                                      _c("v-checkbox", {
                                                        attrs: {
                                                          value:
                                                            attrs.inputValue,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.params.promo_category_name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.params,
                                          "promo_category_name",
                                          $$v
                                        )
                                      },
                                      expression: "params.promo_category_name",
                                    },
                                  })
                                : item.key === "vendor"
                                ? _c("v-autocomplete", {
                                    attrs: {
                                      label: item.name,
                                      items: _vm.sortedVendors,
                                      "item-text": "name",
                                      "item-value": "id",
                                      multiple: "",
                                      "small-chips": "",
                                      "deletable-chips": "",
                                      "hide-details": "",
                                      clearable: "",
                                      outlined: "",
                                      dense: "",
                                    },
                                    on: {
                                      change: _vm.handleVendorChange,
                                      "click:clear": function ($event) {
                                        return _vm.removeFilter(item.key, true)
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function ({ item, on, attrs }) {
                                            return [
                                              _c(
                                                "v-list-item",
                                                _vm._g(
                                                  _vm._b(
                                                    {},
                                                    "v-list-item",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-list-item-action",
                                                    [
                                                      _c("v-checkbox", {
                                                        attrs: {
                                                          value:
                                                            attrs.inputValue,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.params.vendor,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.params, "vendor", $$v)
                                      },
                                      expression: "params.vendor",
                                    },
                                  })
                                : item.key === "is_shipper"
                                ? _c(
                                    "span",
                                    {
                                      attrs: {
                                        value: _vm.params.is_shipper,
                                        label: item.name,
                                      },
                                    },
                                    [_vm._v(" Shippers/Pallets ")]
                                  )
                                : item.key === "private_label_indicator"
                                ? _c(
                                    "span",
                                    {
                                      attrs: {
                                        value:
                                          _vm.params.private_label_indicator,
                                        label: item.name,
                                      },
                                    },
                                    [_vm._v(" Private Label AMAPs ")]
                                  )
                                : _c("v-textarea", {
                                    attrs: {
                                      label: item.name,
                                      "hide-details": "auto",
                                      "background-color": "input",
                                      prefix: item.prefix || null,
                                      type: item.type,
                                      autofocus:
                                        _vm.selectedFilters.length === 0 &&
                                        item.key === "name",
                                      dense: "",
                                      clearable:
                                        item.key === "some_key"
                                          ? _vm.params[item.key] !== true
                                          : true,
                                      outlined: "",
                                      "auto-grow": "",
                                      rows: "1",
                                      hint:
                                        item.key !== "notes" &&
                                        item.key !== "description"
                                          ? "Press 'Enter' to add multiple search criteria."
                                          : "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        attrs.inputValue
                                          ? null
                                          : _vm.handleInputValue(item)
                                      },
                                      keydown: function ($event) {
                                        return _vm.handleKeydown(
                                          $event,
                                          item.key
                                        )
                                      },
                                      "click:clear": function ($event) {
                                        return _vm.removeFilter(item.key, true)
                                      },
                                    },
                                    model: {
                                      value: _vm.params[item.key],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.params, item.key, $$v)
                                      },
                                      expression: "params[item.key]",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.selectedFilters,
                callback: function ($$v) {
                  _vm.selectedFilters = $$v
                },
                expression: "selectedFilters",
              },
            },
            [
              _vm.selectedFilters.length
                ? [
                    _c(
                      "v-icon",
                      {
                        staticStyle: {
                          cursor: "pointer",
                          "margin-right": "8px",
                        },
                        attrs: { small: "", right: "" },
                        on: { click: _vm.removeAllFilters },
                      },
                      [_vm._v(" mdi-close ")]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "pa-0", attrs: { cols: "1" } },
        [
          _c(
            "v-btn",
            {
              staticStyle: {
                "border-top-left-radius": "0px",
                "border-bottom-left-radius": "0px",
                border: "1px solid #9e9e9e",
                "border-left": "0px",
              },
              attrs: { height: "40", depressed: "", color: "accent" },
              on: { click: _vm.triggerSearch },
            },
            [_c("v-icon", [_vm._v("mdi-magnify")])],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }